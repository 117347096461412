* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  overflow-y: scroll;
  overflow-x: hidden;
}

.layout-container {
  display: flex;
  flex-wrap: nowrap;
  flex-grow: 1;

  & > *:first-child:not(:last-child) {
    flex: 0 0 33%;
  }

  & > *:last-child {
    flex-grow: 1;
  }

  & > * + * {
    margin-left: 24px;
  }

  > * {
    display: flex;
    flex-direction: column;
    max-height: 100%;

    > .MuiCardContent-root {
      flex-grow: 1;
    }
  }
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}
